import React from 'react'

import PropTypes from 'prop-types'

import './category-card.css'

import myImgx from './/images/servrobt.jpeg'

const CategoryCard = (props) => {
  return (
    <div className="category-card-category-card">
      <img
        alt={props.imageAlt}
        src={props.categoryImg}
        className="category-card-image"
       
      />
      <span className="category-card-text">{props.name}</span>
      <a
          href={props.linkTo}
          target="_blank"
          rel="noreferrer noopener"
          className="button"
        >
          Order Now
        </a>
    </div>
  )
}

CategoryCard.defaultProps = {
  categoryImg: {myImgx} ,
  imageAlt: 'image',
  name: 'Service Robot',
  linkTo: 'https://shop.aeronoma.com/products/aerobot-by-aeronoma'
}

CategoryCard.propTypes = {
  categoryImg: PropTypes.string,
  imageAlt: PropTypes.string,
  name: PropTypes.string,
  linkTo: PropTypes.string,
}

export default CategoryCard
