// src/components/Search.js
import React, { useState } from 'react';
import Fuse from 'fuse.js';
import data from '../data'; // Import your structured data

const Search = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  const options = {
    keys: ['title', 'content'],
    includeScore: true,
  };

  const fuse = new Fuse(data, options);

  const handleSearch = (e) => {
    const { value } = e.target;
    setQuery(value);

    if (value) {
      const result = fuse.search(value);
      setResults(result.map(r => r.item)); // Extract the relevant items
    } else {
      setResults([]);
    }
  };

  return (
    <div>
      <input
        type="text"
        value={query}
        onChange={handleSearch}
        placeholder="Search"
        className="home-textinput"
      />
      {results.length > 0 && (
        <ul className="search-results">
          {results.map((item) => (
            <li key={item.id}>
              <a href={item.path}>{item.title}</a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Search;
