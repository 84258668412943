import React from 'react'

import PropTypes from 'prop-types'

import './blog-post-card.css'

import myImg from './images/blog2pic.jpeg'

const BlogPostCard = (props) => {
  return (
    <div className={`blog-post-card-blog-post-card ${props.rootClassName} `}>
      <img
        alt={props.imageAlt}
        src={props.imageSrc}
        className="blog-post-card-image"
      />
      <div className="blog-post-card-container">
        <span className="blog-post-card-text1">{props.newProp}</span>
        <span className="blog-post-card-text2">{props.description}</span>
        <a
          href={props.linkTo}
          target="_blank"
          rel="noreferrer noopener"
          className="button"
        >
          Read more
        </a>
      </div>
    </div>
  )
}

BlogPostCard.defaultProps = {
  imageAlt: 'image',
  rootClassName: '',
  newProp: 'Autonomous Vehicles: Technology, Benefits, and Impact ',
  imageSrc: myImg,
  description: 'The advent of autonomous vehicles (AVs) represents a',
  linkTo: 'https://shop.aeronoma.com/blogs/collections/autonomous-vehicles-technology-benefits-and-impact',
}

BlogPostCard.propTypes = {
  imageAlt: PropTypes.string,
  rootClassName: PropTypes.string,
  newProp: PropTypes.string,
  imageSrc: PropTypes.string,
  description: PropTypes.string,
  linkTo: PropTypes.string,
}

export default BlogPostCard
